<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/invest-with-tma" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Service Request</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addRole">
                            <label>User Name</label>
                            <input type="text" class="form-control form-control-sm mb-3" readonly
                                v-model="requestService.user.profile.name" />
                                <label>Phone</label>
                            <input type="text" class="form-control form-control-sm mb-3" readonly
                                v-model="requestService.user.profile.phone_number" />
                            <label>User Email</label>
                            <input type="text" class="form-control form-control-sm mb-3" readonly
                                v-model="requestService.user.email" />
                                <label>Occupation</label>
                            <input type="text" class="form-control form-control-sm mb-3" readonly
                                v-model="requestService.user.profile.occupation" />
                                <label>UC</label>
                            <input type="text" class="form-control form-control-sm mb-3" readonly
                                v-model="requestService.user.profile.uc" />
                                <label>Tehsil</label>
                            <input type="text" class="form-control form-control-sm mb-3" readonly
                                v-model="requestService.user.profile.tahsil" />
                                <label>District</label>
                            <input type="text" class="form-control form-control-sm mb-3" readonly
                                v-model="requestService.user.profile.district" />
                            <label>Service Type</label>
                            <input type="text" class="form-control form-control-sm mb-3" readonly
                                v-model="requestService.service_type.name" />
                            <label>Co-Fund Amount</label>
                            <input type="text" class="form-control form-control-sm mb-3" readonly
                                v-model="requestService.co_fund_amount" />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import router from "@/router";

export default {
    data() {
        return {
            requestService: {
                user: {
                    email: '',
                    profile:{
                        'name':"",
                        'phone_number':"",
                        'occupation':"",
                         'uc':"",
                         'tahsil':"",
                         'district':"",
                    }
                },
                service_type: {
                    name: ''
                }
            },
            serviceId: null,
            loading: false,
            modules: [],
        };
    },
    mounted() {
        this.serviceId = this.$route.params.id;
        this.fetch(this.serviceId);
    },
    methods: {
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("investWithTma/getSingle", { id })
                .then((response) => {
                    this.requestService = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    if (e.error) {
                        router.push({ path: "/partnerwithtma" });
                        this.$toast.show("Page Not Found", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 2000,
                        });
                    }
                });
        },
    },
};
</script>
      