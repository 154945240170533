<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <router-link to="/managment-team" class="btn btn-sm btn-primary mb-3">Back</router-link>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Edit Member</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addTeam">
              <label>Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control form-control-sm mb-3" required v-model="team.full_name" />
              <label>Designation <span class="text-danger">*</span></label>
              <input type="text" class="form-control form-control-sm mb-3" required v-model="team.desigantion" />
              <label>Image <span class="text-danger">*</span></label><br />
              <input type="file" ref="fileInput" accept="image/*" class="form-control" @change="handleImageUpload"  />
             
                 <div class="col-md-6">
                  <img v-if="imagePreview" :src="imagePreview" alt="Image Preview"
                                class="mt-3 w-100" />
                 </div>

              <label class="mt-3">Bio</label>
              <textarea name="" id=""  v-model="team.bio" class="form-control" cols="30" rows="10"></textarea>

              <div class="row mt-3">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import router from "@/router";

export default {
  components: {
    QuillEditor,
  },
  data() {
    return {
      team: {
        image: null,
      },
      teamId: null,
      loading: false,
      imagePreview: null,
      modules: [],
    };
  },
  mounted() {
    this.teamId = this.$route.params.id;
    this.fetch(this.teamId);
  },
  methods: {
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.team.image = e.target.result;
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("managmentTeam/getSingle", { id })
        .then((response) => {
          this.team = response.data;
          this.imagePreview=this.team.media.image_url;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.team);
      const id = this.teamId;
      this.$store
        .dispatch("managmentTeam/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          } else {
            this.$toast.show("Failed to udpate", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>
  