<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-12 mt-4">
                <div class="card">
                    <div class="card-header text-center">
                        <h3 class="text-black">Web Content</h3>
                    </div>
                    <div class="card-body">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">

                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="logo-tab" data-bs-toggle="tab"
                                    data-bs-target="#logo" type="button" role="tab" aria-controls="logo"
                                    aria-selected="true">Logo</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="hero-tab" data-bs-toggle="tab" data-bs-target="#hero"
                                    type="button" role="tab" aria-controls="hero" aria-selected="true">Home</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="about-tab" data-bs-toggle="tab" data-bs-target="#about"
                                    type="button" role="tab" aria-controls="about" aria-selected="false">About</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="privacy-tab" data-bs-toggle="tab" data-bs-target="#privacy"
                                    type="button" role="tab" aria-controls="privacy" aria-selected="false">How it
                                    works</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="feature-tab" data-bs-toggle="tab" data-bs-target="#feature"
                                    type="button" role="tab" aria-controls="feature"
                                    aria-selected="false">Testimonials</button>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="logo" role="tabpanel" aria-labelledby="logo-tab">
                                <h3 class="text-center">Logo</h3>
                                <div class="container mt-4">
                                    <div class="col-md-12 d-flex flex-column align-items-center text-center">
                                        <img v-if="preview.logo" :src="preview.logo" alt="Image Preview"
                                            class="mt-2 mb-2"
                                            style="width: 30%; height: 10%; object-fit: cover; border-radius: 8px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">
                                        <div class="form-group">
                                            <label class="form-label">
                                            </label>
                                            <input type="file" @change="onFileChangeLogo" class="form-control"
                                                accept="image/*">
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-md-2">
                                            <button @click="submit" class="btn btn-lg btn-primary w-100">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="hero" role="tabpanel" aria-labelledby="hero-tab">
                                <div class="container mt-4">
                                    <div class="row mt-4">
                                        <div class="col-md-6 mt-3">
                                            <img v-if="preview.bannerImage" :src="preview.bannerImage"
                                                alt="Image Preview" class="mt-2 mb-2"
                                                style="width: 25rem; height: 25rem; object-fit: cover; border-radius: 8px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">
                                            <div class="form-group">
                                                <label class="form-label">
                                                    Select Banner Image
                                                </label>
                                                <input type="file" @change="onFileChangeBanner" class="form-control"
                                                    accept="image/*">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="title">Title</label>
                                                <input type="text" v-model="data.home.title" class="form-control"
                                                    id="title" name="title">
                                            </div>
                                            <div class="form-group">
                                                <label for="title">Description</label>
                                                <textarea type="text" v-model="data.home.description" rows="6"
                                                    class="form-control" id="title" name="title"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <img src="../../assets/images/home2.png" alt="Image Preview"
                                                class="img-fluid mt-2 mb-2 w-100">
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-md-2">
                                            <button @click="submit" class="btn btn-lg btn-primary w-100">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="about" role="tabpanel" aria-labelledby="about-tab">
                                <div class="container">
                                    <h3 class="text-center">About Us</h3>
                                    <div class="container">
                                        <div class="row">

                                            <h3 class="text-center">Section 1</h3>


                                            <div class="col-md-4">


                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" v-model="data.aboutus.title"
                                                        class="form-control">
                                                </div>


                                                <div class="form-group">
                                                    <label>Description</label>
                                                    <textarea type="text" rows="9" v-model="data.aboutus.description"
                                                        class="form-control"></textarea>
                                                </div>



                                            </div>

                                            <div class="col-md-4">

                                                <img v-if="preview.aboutus.image1" :src="preview.aboutus.image1"
                                                    alt="Image Preview" class="mt-2 mb-2"
                                                    style="width: 100%; height: 17rem; object-fit: cover; border-radius: 8px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">

                                                <div class="form-group">
                                                    <label class="form-label">
                                                        Select Banner Image
                                                    </label>
                                                    <input type="file" @change="onFileChangeAboutus($event, 1)"
                                                        class="form-control" accept="image/*">
                                                </div>


                                            </div>
                                            <div class="col-md-4">


                                                <img v-if="preview.aboutus.image2" :src="preview.aboutus.image2"
                                                    alt="Image Preview" class="mt-2 mb-2"
                                                    style="width: 100%; height: 17rem; object-fit: cover; border-radius: 8px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">

                                                <div class="form-group">
                                                    <label class="form-label">
                                                        Select Banner Image
                                                    </label>
                                                    <input type="file" @change="onFileChangeAboutus($event, 2)"
                                                        class="form-control" accept="image/*">
                                                </div>

                                            </div>


                                        </div>
                                        <div class="row">

                                            <h3 class="text-center">Section 2</h3>

                                            <div class="col-md-4 feature-card">

                                                <img v-if="preview.aboutus.sec1_icon" :src="preview.aboutus.sec1_icon"
                                                    alt="Image Preview" class="mt-2 mb-2"
                                                    style=" width: 70px ; height: 70px ; object-fit: cover; ">

                                                <div class="form-group">
                                                    <label class="form-label">
                                                        <b>Icon</b>
                                                    </label>
                                                    <input type="file" class="form-control"
                                                        @change="onFileChangeFeature($event, 'sec1_icon')"
                                                        accept="image/*">
                                                </div>


                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" v-model="data.aboutus.sec1_title"
                                                        class="form-control">
                                                </div>

                                                <div class="form-group">
                                                    <label>Description</label>
                                                    <textarea type="text" v-model="data.aboutus.sec1_description"
                                                        class="form-control" id="title" rows="3" name="title" />
                                                </div>
                                            </div>

                                            <div class="col-md-4 feature-card">

                                                <img v-if="preview.aboutus.sec2_icon" :src="preview.aboutus.sec2_icon"
                                                    alt="Image Preview" class="mt-2 mb-2"
                                                    style=" width: 70px ; height: 70px ; object-fit: cover; ">

                                                <div class="form-group">
                                                    <label class="form-label">
                                                        <b>Icon</b>
                                                    </label>
                                                    <input type="file" class="form-control"
                                                        @change="onFileChangeFeature($event, 'sec2_icon')"
                                                        accept="image/*">
                                                </div>


                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" v-model="data.aboutus.sec2_title"
                                                        class="form-control">
                                                </div>

                                                <div class="form-group">
                                                    <label>Description</label>
                                                    <textarea type="text" v-model="data.aboutus.sec2_description"
                                                        class="form-control" id="title" rows="3" name="title" />
                                                </div>
                                            </div>
                                            <div class="col-md-4 feature-card">

                                                <img v-if="preview.aboutus.sec3_icon" :src="preview.aboutus.sec3_icon"
                                                    alt="Image Preview" class="mt-2 mb-2"
                                                    style=" width: 70px ; height: 70px ; object-fit: cover; ">

                                                <div class="form-group">
                                                    <label class="form-label">
                                                        <b>Icon</b>
                                                    </label>
                                                    <input type="file" class="form-control"
                                                        @change="onFileChangeFeature($event, 'sec3_icon')"
                                                        accept="image/*">
                                                </div>


                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" v-model="data.aboutus.sec3_title"
                                                        class="form-control">
                                                </div>

                                                <div class="form-group">
                                                    <label>Description</label>
                                                    <textarea type="text" v-model="data.aboutus.sec3_description"
                                                        class="form-control" id="title" rows="3" name="title" />
                                                </div>
                                            </div>
                                            <div class="col-md-4 feature-card">

                                                <img v-if="preview.aboutus.sec4_icon" :src="preview.aboutus.sec4_icon"
                                                    alt="Image Preview" class="mt-2 mb-2"
                                                    style=" width: 70px ; height: 70px ; object-fit: cover; ">

                                                <div class="form-group">
                                                    <label class="form-label">
                                                        <b>Icon</b>
                                                    </label>
                                                    <input type="file" class="form-control"
                                                        @change="onFileChangeFeature($event, 'sec4_icon')"
                                                        accept="image/*">
                                                </div>


                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" v-model="data.aboutus.sec4_title"
                                                        class="form-control">
                                                </div>

                                                <div class="form-group">
                                                    <label>Description</label>
                                                    <textarea type="text" v-model="data.aboutus.sec4_description"
                                                        class="form-control" id="title" rows="3" name="title" />
                                                </div>
                                            </div>

                                            <div class="col-md-4 feature-card">

                                                <img v-if="preview.aboutus.sec5_icon" :src="preview.aboutus.sec5_icon"
                                                    alt="Image Preview" class="mt-2 mb-2"
                                                    style=" width: 70px ; height: 70px ; object-fit: cover; ">

                                                <div class="form-group">
                                                    <label class="form-label">
                                                        <b>Icon</b>
                                                    </label>
                                                    <input type="file" class="form-control"
                                                        @change="onFileChangeFeature($event, 'sec5_icon')"
                                                        accept="image/*">
                                                </div>


                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" v-model="data.aboutus.sec5_title"
                                                        class="form-control">
                                                </div>

                                                <div class="form-group">
                                                    <label>Description</label>
                                                    <textarea type="text" v-model="data.aboutus.sec5_description"
                                                        class="form-control" id="title" rows="3" name="title" />
                                                </div>
                                            </div>

                                            <div class="col-md-4 feature-card">

                                                <img v-if="preview.aboutus.sec6_icon" :src="preview.aboutus.sec6_icon"
                                                    alt="Image Preview" class="mt-2 mb-2"
                                                    style=" width: 70px ; height: 70px ; object-fit: cover; ">

                                                <div class="form-group">
                                                    <label class="form-label">
                                                        <b>Icon</b>
                                                    </label>
                                                    <input type="file" class="form-control"
                                                        @change="onFileChangeFeature($event, 'sec6_icon')"
                                                        accept="image/*">
                                                </div>


                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" v-model="data.aboutus.sec6_title"
                                                        class="form-control">
                                                </div>

                                                <div class="form-group">
                                                    <label>Description</label>
                                                    <textarea type="text" v-model="data.aboutus.sec6_description"
                                                        class="form-control" id="title" rows="3" name="title" />
                                                </div>
                                            </div>



                                        </div>
                                        <div class="row">

                                            <h3 class="text-center mt-3">Section 3</h3>


                                            <div class="col-md-4 feature-card mt-2">
                                                <div class="form-group">
                                                    <label>Users</label>
                                                    <input type="text" v-model="data.aboutus.div1_user"
                                                        class="form-control">
                                                </div>

                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" v-model="data.aboutus.div1_title"
                                                        class="form-control" id="title" rows="3" name="title" />
                                                </div>
                                            </div>

                                            <div class="col-md-4 feature-card">
                                                <div class="form-group">
                                                    <label>Users</label>
                                                    <input type="text" v-model="data.aboutus.div2_user"
                                                        class="form-control">
                                                </div>

                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" v-model="data.aboutus.div2_title"
                                                        class="form-control" id="title" rows="3" name="title" />
                                                </div>
                                            </div>

                                            <div class="col-md-4 feature-card">
                                                <div class="form-group">
                                                    <label>Users</label>
                                                    <input type="text" v-model="data.aboutus.div3_user"
                                                        class="form-control">
                                                </div>

                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" v-model="data.aboutus.div3_title"
                                                        class="form-control" id="title" rows="3" name="title" />
                                                </div>
                                            </div>

                                            <div class="col-md-4 feature-card">
                                                <div class="form-group">
                                                    <label>Users</label>
                                                    <input type="text" v-model="data.aboutus.div4_user"
                                                        class="form-control">
                                                </div>

                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" v-model="data.aboutus.div4_title"
                                                        class="form-control" id="title" rows="3" name="title" />
                                                </div>
                                            </div>

                                            <div class="col-md-4 feature-card">
                                                <div class="form-group">
                                                    <label>Users</label>
                                                    <input type="text" v-model="data.aboutus.div5_user"
                                                        class="form-control">
                                                </div>

                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" v-model="data.aboutus.div5_title"
                                                        class="form-control" id="title" rows="3" name="title" />
                                                </div>
                                            </div>



                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">

                                                <img v-if="preview.aboutus.image3" :src="preview.aboutus.image3"
                                                    alt="Image Preview" class="mt-1"
                                                    style="width: 25rem; height: 25rem; object-fit: cover; border-radius: 8px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">

                                                <div class="form-group">
                                                    <label class="form-label">

                                                    </label>
                                                    <input type="file" @change="onFileChangeAboutus($event, 3)"
                                                        class="form-control" accept="image/*">
                                                </div>


                                            </div>
                                            <div class="col-md-4 offset-md-1">


                                                <img v-if="preview.aboutus.image4" :src="preview.aboutus.image4"
                                                    alt="Image Preview" class="mt-1"
                                                    style="width: 25rem; height: 25rem; object-fit: cover; border-radius: 8px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">


                                                <div class="form-group">
                                                    <label class="form-label">

                                                    </label>
                                                    <input type="file" @change="onFileChangeAboutus($event, 4)"
                                                        class="form-control" accept="image/*">
                                                </div>

                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-md-2">
                                                <button @click="submit"
                                                    class="btn btn-lg btn-primary w-100">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="feature" role="tabpanel" aria-labelledby="feature-tab">
                                <div class="container">
                                    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="card">
                                                <div
                                                    class="card-header d-flex justify-content-between align-items-center">
                                                    <h5 class="card-title mb-0 text-black">Testimonials</h5>
                                                    <router-link to="/testimonial/add"
                                                        class="btn btn-sm btn-primary mb-2 float-end">Add
                                                        Testimonial</router-link>
                                                </div>
                                                <div class="card-body">
                                                    <data-table :columns="columns" :module="module" :isEdit="isEdit"
                                                        :isDelete="isDelete" :items="items" @deleted="fetchDelete"
                                                        @nextPage="nextPage" @clear="fetch"
                                                        @search="fetch"></data-table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="privacy" role="tabpanel" aria-labelledby="privacy-tab">
                                <div class="container mt-4">
                                    <div class="row">
                                        <h3 class="text-center">How It Works</h3>
                                        <div class="col-md-8 offset-md-2">
                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" v-model="data.howVideo.title" class="form-control">
                                            </div>
                                            <div class="form-group">
                                                <label>Description</label>
                                                <textarea type="text" rows="6" v-model="data.howVideo.description"
                                                    class="form-control" />
                                            </div>
                                            <video class="mt-2 mb-2 w-100" id="video-preview" controls
                                                :src="preview.videoPreview" v-if="preview.videoPreview" />
                                            <div class="form-group">
                                                <label for="videoInput">Choose Video</label>
                                                <input type="file" class="form-control" id="videoInput"
                                                    @change="onFileChangeVideo" accept="video/*">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-md-2">
                                            <button @click="submit" class="btn btn-lg btn-primary w-100">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from "@/components/dataTable.vue";
import { toRaw } from "vue";
import axiosInstance from "@/helpers/axios";

export default {
    components: {
        DataTable,
    },
    data() {
        return {
            items: [],
            columns: [
                {
                    text: "Content",
                    value: "name",
                },
            ],
            loading: false,
            edit: true,
            delete: true,
            videoPreview: "",
            preview: {
                logo: "",
                bannerImage: "",
                videoPreview: "",
                aboutus: {
                    image1: "",
                    image2: "",
                    sec1_icon: "",
                    sec2_icon: "",
                    sec3_icon: "",
                    sec4_icon: "",
                    sec5_icon: "",
                    sec6_icon: "",
                    image3: "",
                    image4: "",
                }
            },
            testimonials: [],
            columns: [
                {
                    text: "Name",
                    value: "name",
                },
                {
                    text: "Description",
                    value: "description",
                },
            ],
            isDelete: true,
            isEdit: true,
            module: "web",
            data: {
                logo: {
                    image: "",
                },
                home: {
                    image: "",
                    title: "",
                    description: "",
                },
                aboutus: {
                    title: "",
                    description: "",
                    image1: "",
                    image2: "",
                    sec1_icon: "",
                    sec1_title: "",
                    sec1_description: "",
                    sec2_icon: "",
                    sec2_title: "",
                    sec2_description: "",
                    sec3_icon: "",
                    sec3_title: "",
                    sec3_description: "",
                    sec4_icon: "",
                    sec4_title: "",
                    sec4_description: "",
                    sec5_icon: "",
                    sec5_title: "",
                    sec5_description: "",
                    sec6_icon: "",
                    sec6_title: "",
                    sec6_description: "",
                    div1_user: "",
                    div1_title: "",
                    div2_user: "",
                    div2_title: "",
                    div3_user: "",
                    div3_title: "",
                    div4_user: "",
                    div4_title: "",
                    div5_user: "",
                    div5_title: "",
                    image3: "",
                    image4: "",
                },
                howVideo: {
                    video: "",
                    title: "",
                    description: "",
                },

            }
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        onFileChange(event, imageNumber, type) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    if (type == 'curesol') {
                        this.data.images[imageNumber] = e.target.result;
                        this.curesolPerviews[imageNumber] = e.target.result;
                    } else {
                        this.data.sections[imageNumber].image = e.target.result;
                        this.sectionPerviews[imageNumber].image = e.target.result;
                    }
                };
                reader.readAsDataURL(file);
            }
        },
        onFileChangeAboutus(event, imageNumber) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.data.aboutus[`image${imageNumber}`] = e.target.result;
                    this.preview.aboutus[`image${imageNumber}`] = e.target.result;

                };
                reader.readAsDataURL(file);
            }
        },
        onFileChangeLogo(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.data.logo.image = e.target.result;
                    this.preview.logo = e.target.result;

                };
                reader.readAsDataURL(file);
            }
        },
        onFileChangeBanner(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.data.home.image = e.target.result;
                    this.preview.bannerImage = e.target.result;

                };
                reader.readAsDataURL(file);
            }
        },


        onFileChangeFeature(event, div) {

            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {

                    this.data.aboutus[div] = e.target.result;
                    this.preview.aboutus[div] = e.target.result;

                };
                reader.readAsDataURL(file);
            }
        },


        onFileChangeVideo(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.preview.videoPreview = e.target.result;
                    this.data.howVideo.video = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },


        submit() {
            this.loading = true;
            const data = toRaw(this.data);
            this.$store
                .dispatch("web/store", { data })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly added", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                });

        },
        fetchDelete() {
            const btn = document.getElementById('feature-tab');
            btn.click();
            this.fetch();
        },
        fetch(search = "") {
            this.loading = true;
            this.$store
                .dispatch("web/getAll", { search, paginate:true })
                .then((response) => {
                    this.loading = false;
                    if (response.data.logo) {
                        this.preview.logo = response.data.logo;
                    }
                    if (response.data.home) {
                        this.data.home = response.data.home;
                        this.preview.bannerImage = this.data.home.imagePerview;
                    }
                    if (response.data.aboutus) {
                        this.data.aboutus = response.data.aboutus;
                        this.preview.aboutus = response.data.aboutus.preview;
                    }
                    if (response.data.howItWork) {
                        this.data.howVideo = response.data.howItWork;
                        this.preview.videoPreview = response.data.howItWork.videPerview;
                    }
                    if (response.data.testimonials) {
                        this.items = response.data.testimonials;
                    }
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        nextPage(url, search = "") {
            this.loading = true;
            const completeUrl = `${url}&paginate=true&search=${search}`;
            axiosInstance.get(completeUrl).then((response) => {
                this.items = response.data.data.testimonials;
                this.loading = false;
            });
        },
    },
};
</script>

<style scoped>
.content-image {
    max-width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nav-tabs .nav-link.active {
    background-color: #4e0189;
    color: #fff;
}

.nav-tabs .nav-link {
    color: #4e0189;
}

.card-header {
    background-color: #4e0189;
    color: #fff;
}

.card-title {
    margin: 0;
}

.tab-content {
    margin-top: 20px;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    font-weight: 600;
}

.img-fluid {

    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#description .ql-container {
    height: 200px;
}

#description .ql-editor {
    min-height: 150px;
}

.ql-container.ql-snow {
    max-width: 100% !important;
}

.feature-card {
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.feature-card:hover {
    transform: translateY(-5px);
}

.feature-icon {
    width: 70px;
    height: 70px;
    object-fit: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
</style>