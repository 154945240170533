<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-4">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="Type here to search..."
            v-model="searchValue"
          />
          <span class="input-group-text" id="basic-addon2" @click="search"
            ><i class="fa fa-search"></i
          ></span>
          <span class="input-group-text" id="basic-addon2" @click="clearSearch"
            ><i class="icon-close"></i
          ></span>
        </div>
      </div>
      <!-- <div class="col-md-8">
        <div class="form-group row d-flex justify-content-end">
          <label class="col-md-6 text-end" style="padding-top: 4px">Per Page</label>
          <div class="col-md-2">
            <select v-model="pageSize" class="form-control form-control-sm">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div> -->
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th v-for="column in columns">{{ column.text }}</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in items.data" :key="i">
                <td v-for="column in columns">
                  <template v-if="column.secondValue">
                    {{
                      item[column.value] &&
                      item[column.value][column.secondValue]
                        ? item[column.value][column.secondValue]
                        : ""
                    }}
                  </template>
                  <template v-else>
                    {{ item[column.value] ? item[column.value] : "" }}
                  </template>
                </td>
                <td style="width: 140px">
                  <div class="d-flex">
                    <div class="form-check form-switch" v-if="statusUpdate">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="toggleSwitch"
                        :checked="isActive(item.status)"
                      />
                    </div>
                    <button
                      v-if="isChangePassword"
                      class="btn p-0 me-2"
                      @click="changePassword(item)"
                    >
                      <i
                        class="fa fa-key text-primary"
                        style="font-size: 18px"
                      ></i>
                    </button>

                    <!-- <button class="btn btn-outline-secondary me-2 p-0 d-flex align-items-baseline"
                      style="border: 1px solid #ddd; padding: 1px 10px !important; color: #4e0189;" type="button"
                      @click="selectUserId(item.id)" v-if="item.status == 'active' && isblockUser">
                      <i class="fa fa-ban me-1" style="font-size: 14px"></i>
                      <span class="text-secondary">Block</span>
                    </button> -->

                    <!-- <button class="btn btn-outline-secondary me-2 p-0 d-flex align-items-baseline"
                      style="border: 1px solid #ddd; padding: 1px 10px !important; color: #4e0189;" type="button"
                      @click="selectUserId(item.id)" v-if="item.status == 'inactive' && isblockUser">
                      <i class="fa fa-ban me-1" style="font-size: 14px"></i>
                      <span class="text-secondary">Unblock</span>
                    </button> -->

                    <button
                      v-if="item.isBlock == 0 && adminBlock"
                      class="btn me-2 p-0"
                      @click="blockAdmin(item)"
                      style="color: #4e0189"
                    >
                      <i class="fa fa-ban me-1" style="font-size: 18px"></i>
                    </button>

                    <button
                      v-if="item.isBlock == 1 && adminBlock"
                      class="btn me-2 p-0"
                      @click="blockAdmin(item)"
                      style="color: #4e0189"
                    >
                      <i class="fa fa-user-check" style="font-size: 18px"></i>
                    </button>

                    <button
                      v-if="item.status == 'active' && isblockUser"
                      class="btn me-2 p-0"
                      @click="selectUserId(item)"
                      style="color: #4e0189"
                    >
                      <i class="fa fa-ban me-1" style="font-size: 18px"></i>
                    </button>

                    <button
                      v-if="item.status == 'inactive' && isblockUser"
                      class="btn me-2 p-0"
                      @click="selectUserId(item)"
                      style="color: #4e0189"
                    >
                      <i class="fa fa-user-check" style="font-size: 18px"></i>
                    </button>

                    <button
                      v-if="viewCampaignRegisterUser"
                      class="btn me-2 p-0"
                      @click="viewRegisterCampaignUser(item)"
                      style="color: #4e0189"
                    >
                      <i class="fa fa-unlock me-1" style="font-size: 18px"></i>
                    </button>

                    <button
                      v-if="supportEmail"
                      class="btn me-2 p-0"
                      @click="openEmail(item)"
                      style="color: #4e0189"
                    >
                    <i class="fa fa-paper-plane me-1" style="font-size: 18px"></i>
                    </button>

                    <button
                      v-if="isViewAble"
                      class="btn me-2 p-0"
                      @click="viewItem(item)"
                      style="color: #4e0189"
                    >
                      <i class="fa fa-eye" style="font-size: 18px"></i>
                    </button>
                    <button
                      v-if="isDisscussion"
                      class="btn me-2 p-0"
                      @click="openDisscussion(item)"
                    >
                      <i
                        class="fa fa-eye text-primary"
                        style="font-size: 18px"
                      ></i>
                    </button>
                    <button
                      v-if="isCampaignUsers"
                      class="btn me-2 p-0"
                      @click="openCampaignUsers(item)"
                      style="color: #4e0189"
                    >
                      <i class="fa fa-user" style="font-size: 18px"></i>
                    </button>
                    <button
                      v-if="isEdit"
                      class="btn me-2 p-0"
                      @click="openItem(item)"
                      style="color: #4e0189"
                    >
                      <i class="fa fa-edit" style="font-size: 18px"></i>
                    </button>
                    <button
                      v-if="isDelete"
                      class="btn p-0"
                      type="button"
                      data-bs-toggle="modal"
                      :data-bs-target="'#deleteModal' + index"
                      @click="selectItem(item)"
                    >
                      <i
                        class="fa fa-trash-o text-danger"
                        style="font-size: 18px"
                      ></i>
                    </button>
                    <div v-if="isSurveyResult">
                      <p>
                        <a
                          href="javascript:void(0);"
                          class="primary-text-color text-underline"
                          data-bs-toggle="modal"
                          data-bs-target="#totalParticipantModal"
                          @click="openSurvey(item.user.email)"
                          >View Result
                        </a>
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <span v-if="items.to && items.from && items.total">
          Showing {{ items.from }} to {{ items.to }} of {{ items.total }}
          {{ entryLabel }}
        </span>
      </div>
      <div class="col-6 text-end">
        <nav aria-label="Page navigation example">
          <ul class="pagination pagination-sm justify-content-end">
            <li class="page-item" v-for="page in items.links" :key="page.id">
              <a
                href="javascript:void(0);"
                class="page-link"
                :class="{ active: page.active }"
                v-if="page.url != null"
                @click="movePages(page.url, searchValue)"
                ><span v-html="page.label"></span
              ></a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <!-- Delete Modal -->
    <div
      class="modal fade"
      :id="'deleteModal' + index"
      tabindex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
      ref="deleteModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Are you sure?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm btn-danger"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button
              type="button"
              class="btn btn-sm btn-success"
              data-bs-dismiss="modal"
              @click="deleteItem"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/router";
import { toRaw } from "vue";

export default {
  props: {
    columns: [],
    items: [],
    module: null,
    isChangePassword: false,
    type: null,
    index: 1,
    statusUpdate: false,
    isEdit: false,
    isDelete: false,
    isViewAble: false,
    isDisscussion: false,
    isSurveyResult: false,
    isCampaignUsers: false,
    isblockUser: false,
    viewCampaignRegisterUser: false,
    adminBlock: false,
    supportEmail:false,
  },
  data() {
    return {
      searchValue: "",
      pageSize: 10,
      currentPage: 1,
      totalPages: 1,
      loading: false,
      selectedItem: null,
    };
  },

  computed: {
    entryLabel() {
      return this.items.total === 1 ? "entry" : "entries";
    },
  },
  methods: {
    changePassword(item) {
      router.push({
        path: `/${this.module}/changePassword/${item.encrypted_id}`,
      });
    },
    selectItem(item) {
      this.selectedItem = item;
    },

    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    openItem(item) {
      if (item.parent_id) {
        router.push({
          path: `/${this.module}/edit/${item.encrypted_id}`,
        });
      } else {
        if (this.module == "users") {
          router.push({
            path: `/${this.module}/edit/${item.encrypted_id}`,
            query: { type: this.type },
          });
        } else {
          router.push({ path: `/${this.module}/edit/${item.encrypted_id}` });
        }
      }
    },
    viewItem(item) {
      router.push({ path: `/${this.module}/view/${item.encrypted_id}` });
    },
    openCampaignUsers(item) {
      router.push({ path: `/${this.module}/users/${item.encrypted_id}` });
    },
    openDisscussion(item) {
      router.push({
        path: `/${this.module}/disscusion/${item.encrypted_id}`,
      });
    },

    viewRegisterCampaignUser(item) {
      router.push({
        path: `/users/view/${item.user.id}`,
      });
    },

    deleteItem() {
      this.loading = true;
      const id = toRaw(this.selectedItem.id);
      this.$store
        .dispatch(`${this.module}/delete`, { id })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly deleted", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });

          this.$emit("deleted");
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    isActive(status) {
      return status == 1;
    },
    selectUserId(id) {
      this.$emit("blockUser", id);
    },
    blockAdmin(admin) {
      this.$emit("blockAdmin", admin);
    },
    openSurvey(email) {
      this.$emit("open-survey", email);
    },
    movePages(url, search) {
      if (process.env.NODE_ENV && process.env.NODE_ENV == "production") {
        url = url.replace("http://", "https://");
      }
      this.$emit("nextPage", url, search);
    },
    clearSearch() {
      this.searchValue = "";
      this.$emit("clear", this.searchValue);
    },
    search() {
      this.$emit("search", this.searchValue);
    },
    openEmail(item) {
        const email = item.email; 
        const subject = "Support Request";
        const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${encodeURIComponent(subject)}`;
        window.open(gmailUrl, '_blank');
    }
  },
};
</script>
