<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-12 mt-4">
                <div class="card">
                    <div class="card-header d-flex align-items-center justify-content-between">
                        <h5 class="mb-0">{{ allMedia?.name }} GALLERY</h5>
                        <div class="row">
                            <div class="col-12 d-flex flex-wrap">
                                <button @click="triggerFileInput"
                                    v-if="permissions && permissions.includes('gallary-Add')"
                                    class="btn btn-sm btn-primary d-flex align-items-center ms-2 mb-2">
                                    <vue-feather type="upload" class="text-top me-1"></vue-feather>
                                    Upload
                                </button>
                                <input type="file" @change="handleFiles" ref="fileInput" class="d-none" multiple>

                                <button v-if="selectionMode" @click="undoDelete"
                                    class="btn btn-sm btn-secondary d-flex align-items-center ms-2 mb-2">
                                    <vue-feather type="corner-up-left" class="text-top me-1"></vue-feather>
                                    Undo
                                </button>

                                <button v-if="selectionMode" @click="toggleSelectAll"
                                    class="btn btn-sm btn-primary ms-2 mb-2">
                                    <span v-if="selectedMedia.length === allMedia.files.length">Unselect All</span>
                                    <span v-else>Select All</span>
                                </button>

                                <button @click="toggleSelectionMode"
                                    v-if="permissions && permissions.includes('gallary-Add') && selectedMedia.length == 0 && (allMedia.files?.length || 0) > 0"
                                    class="btn btn-sm btn-danger d-flex align-items-center ms-2 mb-2">
                                    <vue-feather type="trash" class="text-top me-1"></vue-feather>
                                    <span>Delete</span>
                                </button>

                                <button
                                    v-if="permissions && permissions.includes('gallary-Add') && selectedMedia.length > 0"
                                    data-bs-toggle="modal" data-bs-target="#mediaDeleteModal"
                                    class="btn btn-sm btn-danger d-flex align-items-center ms-2 mb-2">
                                    <vue-feather type="trash" class="text-top me-1"></vue-feather>
                                    Delete {{ selectedMedia.length }}
                                </button>
                            </div>
                        </div>

                    </div>
                    <!-- Add Media Section -->
                    <div class="upload__media-wrap gallery my-gallery card-body row" v-if="mediaArray.length > 0">
                        <div v-for="(media, index) in mediaArray" :key="index" class="upload__media-box">
                            <div v-if="media.type === 'image'" :style="{ backgroundImage: 'url(' + media.src + ')' }"
                                class="img-bg">
                                <div class="upload__media-close" @click="removeMedia(index)"></div>
                            </div>
                            <div v-else class="img-bg">
                                <video :src="media.src" controls class="video-preview"></video>
                                <div class="upload__media-close" @click="removeMedia(index)"></div>
                            </div>
                        </div>
                        <div class=" mt-4 d-flex flex-row" v-if="mediaArray.length > 0">
                            <button type="submit" class="btn btn-lg btn-primary me-2" @click="submit">
                                Save
                            </button>
                            <button type="button" class="btn btn-lg btn-secondary " @click="handleCancel">
                                Cancel
                            </button>
                        </div>
                    </div>
                    <!-- Gallery Section -->
                    <div class="gallery my-gallery card-body row" itemscope="">
                        <figure v-for="(media, index) in allMedia.files" :key="index" class="col-xl-3 col-md-4 col-6">
                            <div class="media-container">
                                <!-- Display Checkbox in Selection Mode -->
                                <div v-if="selectionMode" class="checkbox-container">
                                    <input type="checkbox" :checked="isSelected(media)"
                                        @change="toggleSelectMedia(media)" />
                                </div>
                                <img v-if="media.type == 'Image'" :src="media.image_url" alt="Image description"
                                    class="img-thumbnail" />
                                <video v-else :src="media.image_url" controls class="img-thumbnail"></video>
                            </div>
                        </figure>
                    </div>
                    <vue-easy-lightbox :visible="visible" :index="index" :imgs="lightBoxImages"
                        @hide="handleHide"></vue-easy-lightbox>
                </div>
            </div>
        </div>
        <!-- Delete Modal -->
        <div class="modal fade" id="mediaDeleteModal" tabindex="-1" aria-labelledby="deleteModalLabel"
            aria-hidden="true" ref="deleteModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <p>Please confirm: Do you want to delete {{ selectedMedia.length }} items??</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-danger" data-bs-dismiss="modal">
                            No
                        </button>
                        <button type="button" class="btn btn-sm btn-success" data-bs-dismiss="modal"
                            @click="deleteSelectedMedia">
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            id: "",
            allMedia: [],
            selectedMedia: [],
            selectionMode: false,
            mediaArray: [],
            loading: false,
        };
    },
    mounted() {
        this.id = this.$route.params.id;
        this.permissions = localStorage.getItem("permissions");
        this.fetch(this.id);
    },
    methods: {
        fetch(id) {
            this.loading = true;
            this.$store.dispatch("gallary/folderMedia", { id })
                .then((response) => {
                    this.allMedia = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
        },

        handleFiles(event) {
            const files = event.target.files;
            const filesArr = Array.prototype.slice.call(files);
            filesArr.forEach((file) => {
                const mediaType = file.type.startsWith('image') ? 'image' : (file.type.startsWith('video') ? 'video' : null);
                if (!mediaType) return;
                if (this.mediaArray.length >= this.maxLength) return;
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.mediaArray.push({
                        name: file.name,
                        src: e.target.result,
                        type: mediaType,
                    });
                };
                reader.readAsDataURL(file);
            });
        },
        submit() {
            this.loading = true;
            const data = {
                'parent_id': this.id,
                'media': this.mediaArray
            };
            this.$store
                .dispatch("gallary/storeGallaryMedia", { data })
                .then((response) => {
                    this.$toast.show("Successfuly added", {
                        theme: "outline",
                        position: "top",
                        type: "success",
                        duration: 5000,
                    });
                    this.mediaArray = [];
                    this.fetch(this.id);
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        toggleSelectionMode() {
            this.selectionMode = !this.selectionMode;
            if (!this.selectionMode) {
                this.selectedMedia = [];
            }
        },

        triggerFileInput() {
            this.$refs.fileInput.click();
        },

        toggleSelectMedia(media) {
            if (this.selectedMedia.includes(media)) {
                this.selectedMedia = this.selectedMedia.filter(item => item !== media);
            } else {
                this.selectedMedia.push(media);
            }
        },

        isSelected(media) {
            return this.selectedMedia.includes(media);
        },

        toggleSelectAll() {
            if (this.selectedMedia.length === this.allMedia.files.length) {
                this.selectedMedia = [];
            } else {
                this.selectedMedia = [...this.allMedia.files];
            }
        },

        deleteSelectedMedia() {
            this.allMedia.files = this.allMedia.files.filter(media => !this.selectedMedia.includes(media));
            const data = this.selectedMedia;
            this.loading = true;
            this.$store
                .dispatch("gallary/deleteMedia", { data })
                .then((response) => {
                    this.$toast.show("Successfuly deleted", {
                        theme: "outline",
                        position: "top",
                        type: "success",
                        duration: 5000,
                    });
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
            this.selectedMedia = [];
            this.selectionMode = false;
        },
        undoDelete() {
            this.selectionMode = false;
            this.selectedMedia = [];
        }
    },
};
</script>


<style scoped>
html * {
    box-sizing: border-box;
}

p {
    margin: 0;
}

.upload__box {
    padding: 40px;
}

.upload__inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.upload__btn {
    display: inline-block;
    font-weight: 600;
    color: #fff;
    text-align: center;
    min-width: 116px;
    padding: 5px;
    transition: all 0.3s ease;
    cursor: pointer;
    border: 2px solid;
    background-color: #4045ba;
    border-color: #4045ba;
    border-radius: 10px;
    line-height: 26px;
    font-size: 14px;
}

.upload__btn:hover {
    background-color: unset;
    color: #4045ba;
    transition: all 0.3s ease;
}

.upload__btn-box {
    margin-bottom: 10px;
}

.upload__media-wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.upload__media-box {
    width: 200px;
    padding: 0 10px;
    margin-bottom: 12px;
    position: relative;
}

.upload__media-close {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: center;
    line-height: 24px;
    z-index: 1;
    cursor: pointer;
}

.upload__media-close:after {
    content: "\2716";
    font-size: 14px;
    color: white;
}

.img-bg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    padding-bottom: 100%;
}

.video-bg {
    position: relative;
    padding-bottom: 56.25%;
}

.video-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.media-container {
    position: relative;
    overflow: hidden;
    padding-bottom: 75%;
    /* Aspect ratio: 4:3 (100% / 4 * 3) */
    height: 0;
}

.media-container img,
.media-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the content covers the container */
}

.img-thumbnail {
    border: none;
    /* Optional: remove borders if any */
    border-radius: 8px;
    /* Optional: round the corners */
}

.checkbox-container {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    background-color: transparent;
    padding: 5px;
    border-radius: 50%;
}

.checkbox-container input[type="checkbox"] {
    width: 20px;
    height: 20px;
}
</style>