<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <router-link to="/campaigns" class="btn btn-sm btn-primary mb-3">Back</router-link>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Edit Campaign</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addRole">
              <label>Title <span class="text-danger">*</span></label>
              <input type="text" class="form-control form-control-sm mb-3" required v-model="campaign.title" />
              <label>Campaign Type<span class="text-danger">*</span></label>
              <select class="custom-select mb-3" required v-model="campaign.campaign_type">
                <option value="" selected>Select Campaign Type</option>
                <option v-for="t in types" :value="t.id" :key="t.id">{{ t.name }}</option>
              </select>
              <label>Start Date <span class="text-danger">*</span></label>
              <input type="date" class="form-control form-control-sm mb-3" required v-model="campaign.start_date" />
              <label>End Date <span class="text-danger">*</span></label>
              <input type="date" class="form-control form-control-sm mb-3" required v-model="campaign.end_date" />
              <label>Upload Cover Image/Video <span class="text-danger">*</span></label> <br />
              <input ref="fileInput" type="file" accept="image/*, video/*" class="form-control"
                @change="handleImageUpload" />

                <img v-if="mediaType == 'Image'" :src="imagePreview" alt="Image Preview"  class="mt-2 w-100 mb-2"
                    />

                  <video
                    class="mt-2 mb-2 w-100"
                    id="video-preview"
                    controls
                    :src="imagePreview"
                   v-if="mediaType=='Video'"
                  />


              <div class="d-flex align-items-center mt-4">
                <div class="d-flex align-items-center">
                  <label class="me-3">Request Volunteers</label>
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="registervolunteer"
                      v-model="campaign.register_volunteers" :true-value="1" :false-value="0" />
                  </div>
                </div>
              </div>
              <label class="mt-2">Description</label>
              <QuillEditor v-model="campaign.detail" :editorHeight="'300px'" :editorWidth="'800px'" />
              <div class="row mt-3">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import router from "@/router";

export default {
  components: {
    QuillEditor,
  },
  data() {
    return {
      campaign: {
        image: null,
      },
      campaignId: null,
      loading: false,
      imagePreview: null,
      mediaType: "",
      module: "campaigns",
      types: []
    };
  },
  mounted() {
    this.campaignId = this.$route.params.id;
    this.fetch(this.campaignId);
    this.fetchCompaignsTypes();
  },
  methods: {
    handleImageUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();  
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.campaign.image = e.target.result;
          this.mediaType = this.campaign.image.startsWith('data:image') ? 'Image' : this.campaign.image.startsWith('data:video') ? 'Video' : null;
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("campaigns/getSingle", { id })
        .then((response) => {
          this.campaign = response.data;
          this.mediaType = this.campaign.media.type;
          this.imagePreview = this.campaign.media.image_url;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          if (e.error) {      
            router.push({ path: "/campaigns" });
            this.$toast.show("Page Not Found", {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 2000,
          });          
          }
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.campaign);
      const id = this.campaignId;
      this.$store
        .dispatch("campaigns/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          } else {
            this.$toast.show("Failed to udpate", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    fetchCompaignsTypes() {
      this.loading = true;
      this.$store
        .dispatch("campaigntypes/getAll")
        .then((response) => {
          this.loading = false;
          this.types = response.data;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>