<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/tma_events" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Edit Event</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addTeam">
                            <label>Title <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" required
                                v-model="event.name" />
                            <!-- New input for description -->

                            <div class="d-flex flex-row align-items-center mb-2">
                                <div>
                                    <label class="me-3 mb-0">Select TMA's</label>
                                </div>
                                <div class="d-flex align-items-center">
                                    <input type="checkbox" :checked="event.tmas?.length == tmas.length"
                                        style="margin: 0;" @change="toggleSelectAll" id="selectAll"
                                        class="form-check-input me-2">
                                    <label for="selectAll" class="mb-0">
                                        {{ (event.tmas && event.tmas.length === tmas.length) ? 'Unselect All' : 'Select All' }}
                                    </label>
                                </div>
                            </div>

                            <multiselect class="mb-3" v-model="event.tmas" :options="tmas" :multiple="true"
                                :close-on-select="false" placeholder="Select TMA's" :custom-label="tmaFormatedName"
                                track-by="id">
                            </multiselect>


                            <label>Date & Time<span class="text-danger">*</span></label>
                            <input type="datetime-local" class="form-control form-control-sm mb-3" v-model="event.date"
                                required />

                            <label>Event Type<span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" v-model="event.event_type"
                                required />

                            <label>Location<span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm mb-3" v-model="event.location"
                                required />

                            <label>Upload Image<span class="text-danger">*</span></label><br />
                            <input ref="video" type="file" accept="image/*" class="form-control"
                                @change="handleImageUpload" />
                            <img v-if="mediaType === 'image'" :src="imagePreview" alt="Image Preview"
                                class="mt-2 w-100" />
                            <video class="mt-2  w-100" id="video-preview" controls :src="imagePreview"
                                v-if="mediaType == 'video'" />

                            <br />
                            <div>
                                <label class="mt-2">PDF Document</label>
                                <input ref="fileInput" @change="uploadPDF" type="file" accept="application/pdf"
                                    class="form-control" />
                            </div>

                            <div v-if="pdfUrl" class="mt-2">
                                <a class="mt-2 link-with-underline" v-if="pdfUrl" :href="pdfUrl" target="_blank">View
                                    PDF</a>
                            </div>
                            <label class="mt-3">Detail</label>
                            <QuillEditor v-model="event.description" :editorHeight="'300px'" :editorWidth="'800px'" />
                            <div class="row mt-3">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";

export default {
    components: {
        QuillEditor,
    },
    data() {
        return {
            event: [],
            eventId: null,
            loading: false,
            imagePreview: null,
            pdfPreview: null,
            mediaType: "",
            pdfUrl: '',
            tmas: [],
        };
    },
    mounted() {
        this.eventId = this.$route.params.id;
        this.fetchTmas();
        this.fetch(this.eventId);
    },
    methods: {
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("tma_events/getSingle", { id })
                .then((response) => {
                    this.event = response.data;
                    console.log("event", this.event);
                    this.imagePreview = this.event.media.image_url;
                    this.mediaType = "image";
                    if (this.event.pdf && this.event.pdf.image_url) {
                        this.pdfUrl = this.event.pdf.image_url;
                    }
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
        handleImageUpload(event) {
            const file = event.target.files[0];
            const reader = new FileReader();
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.event.image = e.target.result;
                    this.mediaType = this.event.image.startsWith('data:image') ? 'image' : this.event.image.startsWith('data:video') ? 'video' : null;
                    this.imagePreview = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        uploadPDF(event) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64String = btoa(
                    new Uint8Array(reader.result)
                        .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
                this.event.document = `data:application/pdf;base64,${base64String}`;
                const blob = new Blob([reader.result], { type: 'application/pdf' });
                this.pdfUrl = URL.createObjectURL(blob);
            };
            reader.readAsArrayBuffer(file);
        },
        submit() {
            this.loading = true;
            const data = toRaw(this.event);
            const id = this.eventId;
            this.$store
                .dispatch("tma_events/update", { data, id })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly updated", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    } else {
                        this.$toast.show("Failed to udpate", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        tmaFormatedName(tma) {
            return tma.name + ' District:' + tma.district.name;
        },
        fetchTmas() {
            this.loading = true;
            const id = localStorage.getItem("uid");
            this.$store
                .dispatch("admins/getAllTmas", { query: { id } })
                .then((response) => {
                    this.tmas = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    console.log(e.error);
                    this.loading = false;
                });
        },
        toggleSelectAll(event) {
            if (event.target.checked) {
                this.event.tmas = this.tmas;

            } else {
                this.event.tmas = [];
            }
        }

    },

};
</script>