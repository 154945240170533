<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <router-link to="/projects" class="btn btn-sm btn-primary mb-3">Back</router-link>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Add Project</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addProject">
              <label>Title <span class="text-danger">*</span></label>
              <input type="text" class="form-control form-control-sm mb-3" required v-model="project.project_name" />

              <label>Location<span class="text-danger">*</span></label>
              <input type="text" class="form-control form-control-sm mb-3" required v-model="project.location" />
              <label>Start Date <span class="text-danger">*</span></label>
              <input type="date" class="form-control form-control-sm mb-3" required v-model="project.start_date" />
              <label>End Date <span class="text-danger">*</span></label>
              <input type="date" class="form-control form-control-sm mb-3" required v-model="project.end_date" />

              <label>Status <span class="text-danger">*</span></label>
              <div class="mb-3">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="statusOngoing" name="status" value="Ongoing"
                    v-model="project.status" required>
                  <label class="form-check-label" for="statusOngoing">Ongoing</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="statusCompleted" name="status" value="Completed"
                    v-model="project.status" required>
                  <label class="form-check-label" for="statusCompleted">Completed</label>
                </div>
              </div>


              <div class="mb-3 d-flex align-items-center">
                <label class="me-3 mb-2">Send Notification</label>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" id="sendNotificationYes"  @change="handleNotificationChange"
                    v-model="project.notification" :true-value="1" :false-value="0" />
                </div>
              </div>


              <label>Reference to<span class="text-danger">*</span></label>
              <div class="mb-3">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="ddcRadio" name="radioStatus" v-model="project.refTo"
                    value="DDC" required>
                  <label class="form-check-label" for="ddcRadio">DDC</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="tdcRadio" name="radioStatus" v-model="project.refTo"
                    value="TDC" required>
                  <label class="form-check-label" for="tdcRadio">TDC</label>
                </div>
              </div>


              <div class="mb-3">
                <label>Upload Cover Image/Video<span class="text-danger">*</span></label>
                    <input  type="file" ref="video" accept="image/*,video/*" class="form-control"
                      @change="handleImageUpload" required />
                  </div>


                  <img v-if="mediaType === 'image'" :src="imagePreview" alt="Image Preview"  class="mt-2 w-100 mb-2"
                    />

                  <video
                    class="mt-2 mb-2 w-100"
                    id="video-preview"
                    controls
                    :src="imagePreview"
                   v-if="mediaType=='video'"
                  />

              <br />
              <label>Description</label>
              <QuillEditor v-model="project.description" :editorHeight="'300px'" :editorWidth="'800px'" />

              <div class="row mt-3">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <button type="button" id="openModel" class="d-none" data-bs-toggle="modal" data-bs-target="#notificationModal">
      Open Modal
    </button>

     <notification_filter @save="filterResult"></notification_filter>
  </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import notification_filter from "@/components/notification_filter.vue";

export default {
  components: {
    QuillEditor,
    notification_filter
  },
  data() {
    return {
      files: [],
      project: {
        image: null,
        images: [],
        status: "",
        notification: 0,
        refTo: "",
        notificationFilter:[],
      },
      loading: false,
      modules: [],
      mediaType: "",
      imagePreview: "",
    };
  },

  methods: {
    handleImageUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();  
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.project.image = e.target.result;
          this.mediaType = this.project.image.startsWith('data:image') ? 'image' : this.project.image.startsWith('data:video') ? 'video' : null;
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
   
    submit() {
      this.loading = true;
      const data = toRaw(this.project);
      this.$store
        .dispatch("projects/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly added", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            Object.keys(this.project).forEach((key) => (this.project[key] = null));
            this.$refs.video.value = '';
            this.project.notification = 0;
            this.mediaType = "";
          } else {
            this.$toast.show("Failed to save", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    handleNotificationChange() {
      if (this.project.notification == 1) {
        const btn = document.getElementById("openModel");
        btn.click();
      }
    },
    filterResult(result)
    {
      this.project.notificationFilter=result;
    }
  },
};
</script>