<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-12 mt-5">
                <router-link to="/campaigns" class="btn btn-sm btn-primary mb-2">Back</router-link>
            </div>
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title">Users Registered for {{ title }}</h5>
                    </div>
                    <div class="card-body">
                        <data-table :columns="columns" :viewCampaignRegisterUser="viewCampaignRegisterUser" :module="module" :items="items" :isEdit="isEdit"
                            :isDelete="isDelete" @deleted="fetch"></data-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from "@/components/dataTable.vue";
import router from "@/router";

export default {
    components: {
        DataTable,
    },
    data() {
        return {
            items: [],
            columns: [
                {
                    text: "User Email",
                    value: "user",
                    secondValue: "email",
                },

                {
                    text: "Registration Date",
                    value: "dates",
                },
                {
                    text: "Registration Time",
                    value: "times",
                },
                {
                    text: "Location",
                    value: "location",
                },

            ],
            module: "campaigns",
            permissions: null,
            isEdit: false,
            isDelete: false,
            campaignId: "",
            viewCampaignRegisterUser:true,
            title: "",
        };
    },
    created() {
        this.campaignId = this.$route.params.id;
        this.fetch(this.campaignId);
        this.permissions = localStorage.getItem("permissions");
    },
    methods: {
        fetch(id) {
            this.loading = true;
            this.$store
                .dispatch("campaigns/getRegisterUserCampaign", { id })
                .then((response) => {
                    if (response.data == null) {
                        router.push({ path: "/campaigns" });
                        this.$toast.show("Not Found", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 2000,
                        });
                        return;
                    }else{
                    this.items = response.data.campaign_users;
                  
                    this.title = response.data.title;
                    this.loading = false;
                    }
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
    },
};
</script>