<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-8 mt-5 mx-auto">
                <!-- <router-link to="/news" class="btn btn-sm btn-primary mb-3">Back</router-link> -->
                <div class="card">
                    <div class="card-header" style="border-bottom: none;">
                        <h4 class="card-title">Settings</h4>
                    </div>
                    <div class="card-body">

                        <div class="mb-4">
                            <h5 class="mb-3" style="border-bottom: 1px solid #dee2e6;">Feedback Modules</h5>
                        </div>

                        <div class="row mt-3" v-for="item in items" :key="item.module">
                            <div class="col-md-8">

                                <div class="col">
                                    <label :for="'toggleSwitch-' + item.module" class="form-label"
                                        style="font-weight: bold;">{{ item.module }}</label>
                                </div>

                                <div class="col-md-9">
                                    This setting allows users to provide feedback for the {{ item.module }} module.
                                </div>



                            </div>
                            <div class="col-md-4">

                                <div>

                                    <input class="form-check-input" type="checkbox" :id="'toggleSwitch-' + item.module"
                                        v-model="item.value" true-value="1" false-value="0" @change="updateValue(item)">

                                </div>
                            </div>
                        </div>

                        <!-- App Settong -->

                        <div class="mt-4">
                            <h5 class="mb-3" style="border-bottom: 1px solid #dee2e6;">App Setting</h5>
                        </div>


                        <div class="container mt-5">


                            <div class="row">
                                <div class="col-md-6">
                                    <span style="font-weight: bold;" v-text="pages[0].name"></span>
                                    <div class="mb-3">
                                        <p>This image will be the TMA Module thumbnail image on the app's home page.</p>
                                    </div>
                                    <input type="file" class="form-control mb-3" placeholder="Choose Image..."
                                        @change="handleImageUpload($event, 0)">
                                </div>
                                <div class="col-md-4 offset-md-2">
                                    <div class="card">
                                        <img :src="pages[0].perview" class="card-img-top img-fluid card-img"
                                            alt="Module 1 Image">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <span style="font-weight: bold;" v-text="pages[1].name"></span>
                                    <div class="mb-3">
                                        <p>This image will be the Projects Module thumbnail image on the app's home page.</p>
                                    </div>
                                    <input type="file" class="form-control mb-3" placeholder="Choose Image..."
                                        @change="handleImageUpload($event, 1)">
                                </div>
                                <div class="col-md-4 offset-md-2">
                                    <div class="card">
                                        <img :src="pages[1].perview" class="card-img-top img-fluid card-img"
                                            alt="Module 2 Image">
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-6">
                                    <span style="font-weight: bold;" v-text="pages[2].name"></span>
                                    <div class="mb-3">
                                        <p>This image will be the Campaigns Module thumbnail image on the app's home page.</p>
                                    </div>
                                    <input type="file" class="form-control mb-3" placeholder="Choose Image..."
                                        @change="handleImageUpload($event, 2)">

                                </div>
                                <div class="col-md-4 offset-md-2">
                                    <div class="card">
                                        <img :src="pages[2].perview" class="card-img-top img-fluid card-img"
                                            alt="Module 1 Image">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <span style="font-weight: bold;" v-text="pages[3].name"></span>
                                    <div class="mb-3">
                                        <p>This image will be the Complaint Module thumbnail image on the app's home page.</p>
                                    </div>
                                    <input type="file" class="form-control mb-3" placeholder="Choose Image..."
                                        @change="handleImageUpload($event, 3)">

                                </div>
                                <div class="col-md-4 offset-md-2">
                                    <div class="card">
                                        <img :src="pages[3].perview" class="card-img-top img-fluid card-img"
                                            alt="Module 1 Image">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <span style="font-weight: bold;" v-text="pages[4].name"></span>
                                    <div class="mb-3">
                                        <p>This image will be the Collaborate with TMA Module thumbnail image on the app's home page.</p>
                                    </div>
                                    <input type="file" class="form-control mb-3" placeholder="Choose Image..."
                                        @change="handleImageUpload($event, 4)">

                                </div>
                                <div class="col-md-4 offset-md-2">
                                    <div class="card">
                                        <img :src="pages[4].perview" class="card-img-top img-fluid card-img"
                                            alt="Module 1 Image">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">

                                    <span style="font-weight: bold;" v-text="pages[5].name"></span>
                                    <div class="mb-3">
                                        <p>This image will be the Active Citizen Module thumbnail image on the app's home page.</p>
                                    </div>
                                    <input type="file" class="form-control mb-3" placeholder="Choose Image..."
                                        @change="handleImageUpload($event, 5)">

                                </div>
                                <div class="col-md-4 offset-md-2">
                                    <div class="card">
                                        <img :src="pages[5].perview" class="card-img-top img-fluid card-img"
                                            alt="Module 1 Image">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">

                                    <span style="font-weight: bold;" v-text="pages[6].name"></span>
                                    <div class="mb-3">
                                        <p>This image will be the Register with TMA Module thumbnail image on the app's home page.</p>
                                    </div>
                                    <input type="file" class="form-control mb-3" placeholder="Choose Image..."
                                        @change="handleImageUpload($event, 6)">

                                </div>
                                <div class="col-md-4 offset-md-2">
                                    <div class="card">
                                        <img :src="pages[6].perview" class="card-img-top img-fluid card-img"
                                            alt="Module 1 Image">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">

                                    <span style="font-weight: bold;" v-text="pages[7].name"></span>
                                    <div class="mb-3">
                                        <p>This image will be the Partner with TMA Module thumbnail image on the app's home page.</p>
                                    </div>
                                    <input type="file" class="form-control mb-3" placeholder="Choose Image..."
                                        @change="handleImageUpload($event, 7)">

                                </div>
                                <div class="col-md-4 offset-md-2">
                                    <div class="card">
                                        <img :src="pages[7].perview" class="card-img-top img-fluid card-img"
                                            alt="Module 1 Image">
                                    </div>
                                </div>
                            </div>



                            <button type="button" class="btn btn-sm btn-primary" @click="submit">
                                Save
                            </button>






                        </div>



                        <!-- App Settong -->



                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";

export default {
    data() {
        return {
            loading: false,
            items: [],
            pages: [
                { name: "", image_url: "", perview: "" },
                { name: "", image_url: "", perview: "" },
                { name: "", image_url: "", perview: "" },
                { name: "", image_url: "", perview: "" },
                { name: "", image_url: "", perview: "" },
                { name: "", image_url: "", perview: "" },
                { name: "", image_url: "", perview: "" },
                { name: "", image_url: "", perview: "" },
            ]
        };
    },

    created() {
        this.fetch();
        this.fetchAllPages();
    },

    methods: {

        handleImageUpload(event, index) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.pages[index].perview = e.target.result;
                    this.pages[index].image_url = e.target.result;

                };
                reader.readAsDataURL(file);
            }
        },

        fetch() {

            this.loading = true;
            this.$store
                .dispatch("feedback/getAll")
                .then((response) => {
                    this.items = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });


        },
        fetchAllPages() {

            this.loading = true;
            this.$store
                .dispatch("feedback/getAllPages")
                .then((response) => {
                    console.log("sss", response.data);

                    response.data.forEach((item, index) => {
                        this.pages[index].name = item.name;
                        this.pages[index].perview = item.media && item.media.image_url ? item.media.image_url : "";
                    });


                    console.log("dsds", this.pages);
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });


        },

        updateValue(data) {
            this.$store
                .dispatch("feedback/update", { data })
                .then((response) => {
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },



        updateName(event, index) {
            this.pages[index].name = event.target.value;
        },




        submit() {


            this.loading = true;
            const data = this.pages;
            this.$store
                .dispatch("feedback/storePage", { data })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        console.log("er", response.data);
                        this.$toast.show("Successfuly added", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                    } else {
                        this.$toast.show("Failed to save", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },







    },
};
</script>


<style>
.card-img {
    height: 50px;
    object-fit: cover;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}
</style>