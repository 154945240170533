<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/active-citizen" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Add Active Citizen</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="submit" ref="addTeam">

                            <label>User Email <span class="text-danger">*</span></label>
                            <select class="custom-select mb-3" required v-model="citizen.user_id">
                                <option value="" selected>Select User</option>
                                <option v-for="t in users" :value="t.id" :key="t.id">{{ t.email }}</option>
                            </select>

                            <!-- <label>Media</label><br />
                            <input ref="fileInput" type="file" accept="image/*,video/*" class="form-control"
                                @change="handleImageUpload" />
                            <br /> -->

                          

                            <label>Short Detail <span class="text-danger">*</span></label>
                            <QuillEditor v-model="citizen.detail" :editorHeight="'300px'" :editorWidth="'800px'" />

                            <div class="row mt-3">
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import notification from "@/store/modules/notification";
import notification_filter from "@/components/notification_filter.vue";

export default {
    components: {
        QuillEditor,
        notification_filter
    },
    data() {
        return {
            files: [],
            citizen: {
                image: null,
                user_id: "",
                detail: "",
                notificationFilter: [],
            },
            loading: false,
            modules: [],
            users: [],
            imagePreview: null,
            notification: 0,
        };
    },

    mounted() {
        this.fetchUsers();
    },

    methods: {
        handleImageUpload(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.citizen.image = e.target.result;
                    this.imagePreview = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        submit() {
            this.loading = true;
            const data = toRaw(this.citizen);
            this.$store
                .dispatch("activeCitizen/store", { data })
                .then((response) => {
                    this.loading = false;
                    if (response.success === true) {
                        this.$toast.show("Successfuly added", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                        Object.keys(this.citizen).forEach((key) => (this.citizen[key] = null));
                        this.citizen.user_id = "";
                    } else {
                        this.$toast.show("Failed to save", {
                            theme: "outline",
                            position: "top",
                            type: "error",
                            duration: 5000,
                        });
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.$toast.show(e.error, {
                        theme: "outline",
                        position: "top",
                        type: "error",
                        duration: 10000,
                    });
                });
        },
        fetchUsers() {
            this.loading = true;
            this.$store
                .dispatch("users/getAll")
                .then((response) => {
                    this.loading = false;
                    this.users = response.data;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
    },
};
</script>