<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <router-link to="/about-tma-videos" class="btn btn-sm btn-primary mb-3">Back</router-link>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Edit Video</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit">
              <label for="title">Title <span class="text-danger">*</span></label>
              <input type="text" id="title" class="form-control form-control-sm mb-3" placeholder="Enter title" required
                v-model="aboutTma.title" />
              <div v-if="uploadOption === 'file'">
                <label>Video<span class="text-danger">*</span></label>
                <div v-if="uploadProgress > 0">
                  <progress :value="uploadProgress" max="100">
                    {{ uploadProgress }}%
                  </progress>
                  <p>{{ uploadProgress }}%</p>
                </div>
                <input ref="video" type="file" accept="video/*" class="mb-4 form-control" @change="handleImageUpload"
                   />
                <video class="mt-2 mb-2 w-100" id="video-preview" controls :src="imagePreview"
                  v-if="mediaType == 'Video'" />
              </div>

              <div v-if="uploadOption === 'link'">
                <label for="videoLink">Video Link <span class="text-danger">*</span></label>
                <input type="url" id="videoLink" class="form-control form-control-sm mb-3"
                  placeholder="Enter video link" required v-model="aboutTma.video_link" @input="handleVideoLinkInput" />
              </div>

              <div class="d-flex align-items-center mt-3">
                <div class="mb-3 d-flex align-items-center">
                  <label class="me-3">Status</label>
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="registervolunteer" v-model="aboutTma.status"
                      :true-value="1" :false-value="0" />
                  </div>
                </div>
                <div class="mb-3 d-flex align-items-center ms-4">
                  <label class="me-3">Send Notification</label>
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="sendNotificationYes"
                      v-model="aboutTma.notification" :true-value="1" :false-value="0" />
                  </div>
                </div>
              </div>

              <label for="shortDetail">Short Detail</label>
              <QuillEditor v-model="aboutTma.detail" :editorHeight="'300px'" :editorWidth="'800px'" />
              <div class="row mt-3">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import router from "@/router";

export default {
  components: {
    QuillEditor,
  },
  data() {
    return {
      aboutTma: {
        video_file: null,
      },
      videoId: null,
      loading: false,
      mediaType: "",
      modules: [],
      imagePreview: null,
      uploadOption: "file"
    };
  },
  mounted() {
    this.videoId = this.$route.params.id;
    this.fetch(this.videoId);
  },
  methods: {
    handleImageUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.aboutTma.video_file = e.target.result;
          this.mediaType = this.aboutTma.video_file.startsWith('data:image') ? 'image' : this.aboutTma.video_file.startsWith('data:video') ? 'Video' : null;
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("aboutTmaVideos/getSingle", { id })
        .then((response) => {
          this.aboutTma = response.data;
          this.mediaType = this.aboutTma.media.type;
          this.imagePreview = this.aboutTma.media.image_url;
          if (this.aboutTma.media.type == 'link') {
            this.uploadOption = "link";
            this.aboutTma.video_link = this.aboutTma.media.image_url;
            console.log("dd", this.aboutTma);
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          if (e.error) {
            console.log("error", e.error);
          }
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.aboutTma);
      const id = this.videoId;
      this.$store
        .dispatch("aboutTmaVideos/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          } else {
            this.$toast.show("Failed to update", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show("Failed to update", {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>