<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mx-auto mt-5">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Edit Role</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addRole">
              <label class="primary-text-color">Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" required v-model="role.name" />
              <table class="table mt-3">
                <tbody>
                  <tr>
                    <td class="checkbox-label">
                      <input type="checkbox" id="checkAll" @change="toggleCheckAll">
                      <label for="checkAll" style="margin-left: 5px;">Check All</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Gallery</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-1" v-model="role.permissions" value="gallary-View">
                      <label for="addCheckbox-1">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-2" v-model="role.permissions" value="gallary-Add">
                      <label for="addCheckbox-2">Add</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Tma Videos</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="tmaVideos-View" v-model="role.permissions"
                        :value="getName('tmaVideos', 'View')">
                      <label for="tmaVideos-View">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="tmaVideos-Add" v-model="role.permissions"
                        :value="getName('tmaVideos', 'Add')">
                      <label for="tmaVideos-Add">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="tmaVideos-Edit" v-model="role.permissions"
                        :value="getName('tmaVideos', 'Edit')">
                      <label for="tmaVideos-Edit">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="tmaVideos-Delete" v-model="role.permissions"
                        :value="getName('tmaVideos', 'Delete')">
                      <label for="tmaVideos-Delete">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Managment Team</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="tmaTeam-View" v-model="role.permissions"
                        :value="getName('tmaTeam', 'View')">
                      <label for="tmaTeam-View">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="tmaTeam-Add" v-model="role.permissions"
                        :value="getName('tmaTeam', 'Add')">
                      <label for="tmaTeam-Add">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="tmaTeam-Edit" v-model="role.permissions"
                        :value="getName('tmaTeam', 'Edit')">
                      <label for="tmaTeam-Edit">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="tmaTeam-Delete" v-model="role.permissions"
                        :value="getName('tmaTeam', 'Delete')">
                      <label for="tmaTeam-Delete">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Projects</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-6" v-model="role.permissions"
                        :value="getName('project', 'View')">
                      <label for="addCheckbox-6">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-7" v-model="role.permissions"
                        :value="getName('project', 'Add')">
                      <label for="addCheckbox-7">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="editCheckbox-8" v-model="role.permissions"
                        :value="getName('project', 'Edit')">
                      <label for="editCheckbox-8">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="deleteCheckbox-9" v-model="role.permissions"
                        :value="getName('project', 'Delete')">
                      <label for="deleteCheckbox-9">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">News</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-10" v-model="role.permissions"
                        :value="getName('newsAndEvent', 'View')">
                      <label for="addCheckbox-10">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-11" v-model="role.permissions"
                        :value="getName('newsAndEvent', 'Add')">
                      <label for="addCheckbox-11">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="editCheckbox-12" v-model="role.permissions"
                        :value="getName('newsAndEvent', 'Edit')">
                      <label for="editCheckbox-12">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="deleteCheckbox-13" v-model="role.permissions"
                        :value="getName('newsAndEvent', 'Delete')">
                      <label for="deleteCheckbox-13">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Events</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="event-View" v-model="role.permissions"
                        :value="getName('event', 'View')">
                      <label for="event-View">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="event-Add" v-model="role.permissions" :value="getName('event', 'Add')">
                      <label for="event-Add">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="event-Edit" v-model="role.permissions"
                        :value="getName('event', 'Edit')">
                      <label for="event-Edit">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="event-Delete" v-model="role.permissions"
                        :value="getName('event', 'Delete')">
                      <label for="event-Delete">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Press Release</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="press-View" v-model="role.permissions"
                        :value="getName('press', 'View')">
                      <label for="press-View">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="press-Add" v-model="role.permissions" :value="getName('press', 'Add')">
                      <label for="press-Add">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="press-Edit" v-model="role.permissions"
                        :value="getName('press', 'Edit')">
                      <label for="press-Edit">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="press-Delete" v-model="role.permissions"
                        :value="getName('press', 'Delete')">
                      <label for="press-Delete">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Disscussion Board</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-40" v-model="role.permissions"
                        :value="getName('disscussionBoard', 'View')">
                      <label for="addCheckbox-40">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-41" v-model="role.permissions"
                        :value="getName('disscussionBoard', 'Add')">
                      <label for="addCheckbox-41">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="editCheckbox-42" v-model="role.permissions"
                        :value="getName('disscussionBoard', 'Edit')">
                      <label for="editCheckbox-42">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="deleteCheckbox-43" v-model="role.permissions"
                        :value="getName('disscussionBoard', 'Delete')">
                      <label for="deleteCheckbox-43">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Polls</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="poll-View" v-model="role.permissions" :value="getName('poll', 'View')">
                      <label for="poll-View">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="poll-Add" v-model="role.permissions" :value="getName('poll', 'Add')">
                      <label for="poll-Add">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="poll-Edit" v-model="role.permissions" :value="getName('poll', 'Edit')">
                      <label for="poll-Edit">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="poll-Delete" v-model="role.permissions"
                        :value="getName('poll', 'Delete')">
                      <label for="poll-Delete">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Survey</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="survey-View" v-model="role.permissions"
                        :value="getName('survey', 'View')">
                      <label for="survey-View">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="survey-Add" v-model="role.permissions"
                        :value="getName('survey', 'Add')">
                      <label for="survey-Add">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="survey-Edit" v-model="role.permissions"
                        :value="getName('survey', 'Edit')">
                      <label for="survey-Edit">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="survey-Delete" v-model="role.permissions"
                        :value="getName('survey', 'Delete')">
                      <label for="survey-Delete">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Campaign Type</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-614" v-model="role.permissions"
                        :value="getName('campaignType', 'View')">
                      <label for="addCheckbox-614">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-615" v-model="role.permissions"
                        :value="getName('campaignType', 'Add')">
                      <label for="addCheckbox-615">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="editCheckbox-616" v-model="role.permissions"
                        :value="getName('campaignType', 'Edit')">
                      <label for="editCheckbox-616">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="deleteCheckbox-617" v-model="role.permissions"
                        :value="getName('campaignType', 'Delete')">
                      <label for="deleteCheckbox-617">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Campaigns</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-14" v-model="role.permissions"
                        :value="getName('campaign', 'View')">
                      <label for="addCheckbox-14">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-15" v-model="role.permissions"
                        :value="getName('campaign', 'Add')">
                      <label for="addCheckbox-15">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="editCheckbox-16" v-model="role.permissions"
                        :value="getName('campaign', 'Edit')">
                      <label for="editCheckbox-16">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="deleteCheckbox-17" v-model="role.permissions"
                        :value="getName('campaign', 'Delete')">
                      <label for="deleteCheckbox-17">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Complaint Type</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-32" v-model="role.permissions"
                        :value="getName('complainType', 'View')">
                      <label for="addCheckbox-32">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-33" v-model="role.permissions"
                        :value="getName('complainType', 'Add')">
                      <label for="addCheckbox-33">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="editCheckbox-34" v-model="role.permissions"
                        :value="getName('complainType', 'Edit')">
                      <label for="editCheckbox-34">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="deleteCheckbox-35" v-model="role.permissions"
                        :value="getName('complainType', 'Delete')">
                      <label for="deleteCheckbox-35">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Complaints</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-36" v-model="role.permissions"
                        :value="getName('complain', 'View')">
                      <label for="addCheckbox-36">View</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Service Types</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-18" v-model="role.permissions"
                        :value="getName('serviceType', 'View')">
                      <label for="addCheckbox-18">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-19" v-model="role.permissions"
                        :value="getName('serviceType', 'Add')">
                      <label for="addCheckbox-19">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="editCheckbox-20" v-model="role.permissions"
                        :value="getName('serviceType', 'Edit')">
                      <label for="editCheckbox-20">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="deleteCheckbox-21" v-model="role.permissions"
                        :value="getName('serviceType', 'Delete')">
                      <label for="deleteCheckbox-21">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Collaboration</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-200" v-model="role.permissions"
                        :value="getName('collaboration', 'View')">
                      <label for="addCheckbox-200">View</label>
                    </td>
                  </tr>

                  <tr>
                    <td style="font-weight: bold;">Active Citizen</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-22" v-model="role.permissions"
                        :value="getName('activeCitizen', 'View')">
                      <label for="addCheckbox-22">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-23" v-model="role.permissions"
                        :value="getName('activeCitizen', 'Add')">
                      <label for="addCheckbox-23">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="editCheckbox-24" v-model="role.permissions"
                        :value="getName('activeCitizen', 'Edit')">
                      <label for="editCheckbox-24">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="deleteCheckbox-25" v-model="role.permissions"
                        :value="getName('activeCitizen', 'Delete')">
                      <label for="deleteCheckbox-25">Delete</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Success Stories</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-26" v-model="role.permissions"
                        :value="getName('successStory', 'View')">
                      <label for="addCheckbox-26">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-27" v-model="role.permissions"
                        :value="getName('successStory', 'Add')">
                      <label for="addCheckbox-27">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="editCheckbox-28" v-model="role.permissions"
                        :value="getName('successStory', 'Edit')">
                      <label for="editCheckbox-28">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="deleteCheckbox-29" v-model="role.permissions"
                        :value="getName('successStory', 'Delete')">
                      <label for="deleteCheckbox-29">Delete</label>
                    </td>
                  </tr>

                  <tr>
                    <td style="font-weight: bold;">Register With TMA</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-201" v-model="role.permissions"
                        :value="getName('registerWithTma', 'View')">
                      <label for="addCheckbox-201">View</label>
                    </td>
                  </tr>

                  <tr>
                    <td style="font-weight: bold;">Partner With TMA</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-210555" v-model="role.permissions"
                        value="partnershipwithtma">
                      <label for="addCheckbox-210555">View</label>
                    </td>
                  </tr>

                  <tr>
                    <td style="font-weight: bold;">App Users</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-100" v-model="role.permissions"
                        :value="getName('appUsers', 'View')">
                      <label for="addCheckbox-100">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-101" v-model="role.permissions"
                        :value="getName('appUsers', 'Edit')">
                      <label for="addCheckbox-101">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-1236" v-model="role.permissions" value="blockUser">
                      <label for="addCheckbox-1236">Block User</label>
                    </td>
                  </tr>


                  <tr>
                    <td style="font-weight: bold;">Contact US</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-202" v-model="role.permissions"
                        :value="getName('aboutUs', 'View')">
                      <label for="addCheckbox-202">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-contactus-add" v-model="role.permissions"
                        value="contactUs-Add">
                      <label for="addCheckbox-contactus-add">Add</label>
                    </td>
                  </tr>

                  <tr>
                    <td style="font-weight: bold;">Reporting</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="reporting-View" v-model="role.permissions" value="reporting">
                      <label for="reporting-View">Access</label>
                    </td>
                  </tr>

                  <tr>
                    <td style="font-weight: bold;">Admins</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-60" v-model="role.permissions"
                        :value="getName('admin', 'View')">
                      <label for="addCheckbox-60">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-61" v-model="role.permissions"
                        :value="getName('admin', 'Add')">
                      <label for="addCheckbox-61">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="editCheckbox-62" v-model="role.permissions"
                        :value="getName('admin', 'Edit')">
                      <label for="editCheckbox-62">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="deleteCheckbox-63" v-model="role.permissions"
                        :value="getName('admin', 'Delete')">
                      <label for="deleteCheckbox-63">Delete</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="deleteCheckbox-64" v-model="role.permissions"
                        :value="getName('admin', 'changePassword')">
                      <label for="deleteCheckbox-64">Change Password</label>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-weight: bold;">Roles</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-70" v-model="role.permissions"
                        :value="getName('role', 'View')">
                      <label for="addCheckbox-70">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-71" v-model="role.permissions"
                        :value="getName('role', 'Add')">
                      <label for="addCheckbox-71">Add</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="editCheckbox-72" v-model="role.permissions"
                        :value="getName('role', 'Edit')">
                      <label for="editCheckbox-72">Edit</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="deleteCheckbox-73" v-model="role.permissions"
                        :value="getName('role', 'Delete')">
                      <label for="deleteCheckbox-73">Delete</label>
                    </td>
                  </tr>



                  <tr>
                    <td style="font-weight: bold;">Notification</td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-203" v-model="role.permissions"
                        :value="getName('notification', 'View')">
                      <label for="addCheckbox-203">View</label>
                    </td>
                    <td class="checkbox-label">
                      <input type="checkbox" id="addCheckbox-204" v-model="role.permissions"
                        :value="getName('notification', 'Add')">
                      <label for="addCheckbox-204">Add</label>
                    </td>
                  </tr>




                </tbody>
              </table>
              <div class="row">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { toRaw } from "vue";
import router from "@/router";

export default {
  data() {
    return {
      role: {
        name: "",
        permissions: [],
      },
      roleId: null,
      loading: false,
      modules: [],
    };
  },
  mounted() {
    this.roleId = this.$route.params.id;
    this.fetchModules();
    this.fetch(this.roleId);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("roles/getSingle", { id })
        .then((response) => {
          if (response.data == null) {
            router.push({ path: "/roles" });
            this.$toast.show("Result Not Found", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 2000,
            });
            return;
          }
          this.role = response.data;
          this.role.permissions.forEach((item) => {
            this.role.permissions.push(item.name);
          });
          this.role.permissions = this.role.permissions.filter(item => typeof item === 'string');
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          if (e.error) {
            router.push({ path: "/roles" });
            this.$toast.show("Page Not Found", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 2000,
            });
          }
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.role);
      const id = this.roleId;
      this.$store
        .dispatch("roles/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.$refs.addRole.reset();
          } else {
            this.$toast.show("Failed to udpate", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },

    fetchModules() {
      this.loading = true;
      this.$store
        .dispatch("module/getAll")
        .then((response) => {
          this.loading = false;
          this.modules = response.data;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    getName(module, action) {
      const permission = this.modules.find(permission => permission.name === `${module}-${action}`);
      return permission ? permission.name : null;
    },
    toggleCheckAll(event) {
      const checkboxes = document.querySelectorAll('input[type="checkbox"]:not(#checkAll)');
      checkboxes.forEach(checkbox => {
        checkbox.checked = event.target.checked;
        if (checkbox.checked && !this.role.permissions.includes(checkbox.value)) {
          this.role.permissions.push(checkbox.value);
        } else if (!checkbox.checked) {
          const index = this.role.permissions.indexOf(checkbox.value);
          if (index !== -1) {
            this.role.permissions.splice(index, 1);
          }
        }
      });
    },
  },
};
</script>


<style>
.table> :not(caption)>*>* {
  border-bottom-width: 0 !important;
}

.checkbox-label {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-bottom: 10px;
}



.checkbox-label input[type="checkbox"] {
  margin-right: 5px;
}
</style>